import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  margin: 3rem auto 0;
  padding: 0 1.5rem;
  text-transform: ${props => (props.capitalize ? 'capitalize' : 'none')};
`

const Title = styled.h2`
  line-height: 1.25;
  text-align: left;
  font-size: 1.5em;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 2.0em;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 80%;
  }
  margin-bottom: 2rem;
`

const Section = styled.div`
  margin-bottom: 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 80%;
  }
  p {
    line-height: 1.5rem;
  }
  ul {
    margin-bottom: 1.5rem;
    li {
     margin-bottom: 1.5rem;
  }
`

const SectionHeader = styled.h3`
  line-height: 1;
  text-align: left;
  font-size: 0.8rem;
  font-weight: 600;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 1.3em;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 80%;
  }
  margin-bottom: 0.9rem;
`

const ItemHeader = styled.h4`
  line-height: 1;
  text-align: left;
  font-size: 0.6rem;
  font-weight: 400;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 1.1rem;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    max-width: 80%;
  }
  margin-bottom: 0.4rem;
`

const Intro = props => {
  return (
    <>
      {props.context.humanPageNumber === 1 && (
        <Wrapper>
          <Section>
            <Title>Brandon is a software developer, researcher, and dancer based in Oakland, California.</Title>
            <p>Brandon received a BA in Modern Culture and Media from Brown University and a master’s from New York University’s Interactive Telecommunications Program.
              He was a 2021-2022 artist in residence at CultureHub.
              He worked as the lead API developer for the Rap Research Lab and was a Yerba Buena Center for the Arts Fellow in 2016-17.
              He presented research at the Playtest Conference for Emerging Media at the Library of Congress in 2018.
              In 2007, he co-founded thePeople, an Oakland based collective focused on the curation and celebration of global, underground, Black music.
              His research is focused on the politics of presence and confronting the exploitation and erasure of bodies and difference through digitization.
              Brandon draws heavily on a decade of experience training capoeira (the Afro-Brazilian “fighting dance”) and more recent study of popular and folkloric dances from Cuba and Angola.</p>
          </Section>
          <Section>
            <SectionHeader>Education</SectionHeader>
            <ul>
              <li>
                <ItemHeader>New York University</ItemHeader>
                <p>Tisch School of the Arts</p>
                <p>Interactive Telecommunications</p>
              </li>
              <li>
                <ItemHeader>Brown University</ItemHeader>
                <p>Modern Culture and Media</p>
              </li>
            </ul>
          </Section>
          <Section>
            <SectionHeader>Residencies and Fellowships</SectionHeader>
            <ul>
              <li>
                <ItemHeader>CultureHub</ItemHeader>
                <p>Los Angeles</p>
                <p>2021/22</p>
              </li>
              <li>
                <ItemHeader>Yerba Buena Center for the Arts</ItemHeader>
                <p>San Francisco</p>
                <p>2016/17</p>
              </li>
            </ul>
          </Section>
        </Wrapper>
      )}
    </>
  )
}

export default Intro
